import React, { useState, useRef, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

import { useMediaQueryContext } from "lib/mediaQueryContext";
import useOnClickOutside from "helpers/hooks/useOnClickOutside";
import colors from "constants/colors";

import CloseSvg from "public/images/close.svg";
import IconMenu from "components/svg/IconMenu";
import IconLogo from "components/svg/IconLogo";
import Button from "components/shared/button/Button";

import {
    HeaderStyled,
    Container,
    Navigation,
    Menu,
    Items,
    Item,
    Logo,
    Buttons
} from "./Header.styled";

function Header() {
    const { desktopView } = useMediaQueryContext();
    const [menuOpen, setMenuOpen] = useState(false);
    const [pageScrolled, setPageScrolled] = useState(() => false);
    const refNavigation = useRef();

    function listenScrollEvent() {
        if (window.scrollY > 30) {
            return setPageScrolled(true);
        }

        return setPageScrolled(false);
    }

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);

        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    useOnClickOutside(refNavigation, () => {
        if (menuOpen) {
            setMenuOpen(false);
        }
    });

    return (
        <HeaderStyled data-testid="Header" pageScrolled={pageScrolled}>
            <Container>
                <Menu>
                    <Button variant="minimal" onClick={() => setMenuOpen(true)}>
                        <IconMenu
                            stroke={
                                pageScrolled ? colors.darkBlue : colors.white
                            }
                        />
                        Menu
                    </Button>
                </Menu>
                <Navigation ref={refNavigation} visible={menuOpen}>
                    <Items>
                        <Item control>
                            <Button
                                variant="minimal"
                                onClick={() => setMenuOpen(false)}
                            >
                                <Image src={CloseSvg} alt="" />
                                Sluiten
                            </Button>
                        </Item>
                        <Item link>
                            <Button
                                href="https://www.cadeaubon.nl/cadeaukaart-keuze/step-one"
                                variant="minimal"
                                target="_blank"
                                rel="noopener norefer"
                                element="a"
                            >
                                Inwisselen
                            </Button>
                        </Item>
                        <Item link>
                            <Link href="/saldocheck" passHref prefetch={false}>
                                <a>Saldocheck</a>
                            </Link>
                        </Item>
                        <Item link>
                            <Link
                                href="/verkooppunten"
                                passHref
                                prefetch={false}
                            >
                                <a>Verkooppunten</a>
                            </Link>
                        </Item>
                        <Item link>
                            <Link href="/zakelijk" passHref prefetch={false}>
                                <a>Zakelijk</a>
                            </Link>
                        </Item>
                        <Item link>
                            <Link href="/helpdesk" passHref prefetch={false}>
                                <a>Helpdesk</a>
                            </Link>
                        </Item>
                        <Item link>
                            <Link
                                href="/verdubbelaar"
                                passHref
                                prefetch={false}
                            >
                                <a>Verdubbelaar</a>
                            </Link>
                        </Item>
                        <Item>
                            <Button
                                variant="regular"
                                element="a"
                                target="_blank"
                                href="https://www.cadeaubon.nl/cadeaubonnen/nationale-keuzekaart/"
                                passHref
                            >
                                Nu bestellen
                            </Button>
                        </Item>
                    </Items>
                </Navigation>
                <Link href="/" passHref prefetch={false}>
                    <Logo>
                        <IconLogo
                            fill={pageScrolled ? colors.darkBlue : colors.white}
                        />
                    </Logo>
                </Link>

                {!desktopView && (
                    <Button
                        variant="underline"
                        element="a"
                        target="_blank"
                        href="https://www.cadeaubon.nl/cadeaubonnen/nationale-keuzekaart/"
                    >
                        Bestellen
                    </Button>
                )}
                {desktopView && (
                    <Buttons>
                        <Button
                            variant="ghost"
                            element="a"
                            href="https://www.cadeaubon.nl/cadeaukaart-keuze/step-one"
                            target="_blank"
                            rel="noopener norefer"
                        >
                            Inwisselen
                        </Button>
                        <Button
                            variant="regular"
                            element="a"
                            target="_blank"
                            href="https://www.cadeaubon.nl/cadeaubonnen/nationale-keuzekaart/"
                        >
                            Nu bestellen
                        </Button>
                    </Buttons>
                )}
            </Container>
        </HeaderStyled>
    );
}

export default Header;
